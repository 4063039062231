import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import { Form, Alert } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import PhoneInput from 'react-phone-number-input';
import "react-phone-number-input/style.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'react-phone-number-input/style.css';
// import picture from '../components/images/key-concept-illustration_114360-6305.webp';
import OTPInput from "otp-input-react";
import mylogo from '../components/images/mylogo.png';
import axios from 'axios';
import pencil from './images/1416596-200.png'
import {
    RecaptchaVerifier,
    signInWithPhoneNumber,
} from "firebase/auth";
import { auth } from '../firebase'
import StripeContainerExt from './StripeContainerExt';
import ClipLoader from "react-spinners/ClipLoader";
import { hostname } from './hostname'


function Extension({ setLoggedInId, paymentPopupProp, load, loggedInId, setIsPaymentDone }) {
    document.title = "Login"
    const [error, setError] = useState("");
    const [paymentPopup, setPaymentPopup] = useState(false);
    const [number, setNumber] = useState("");
    const [result, setResult] = useState("");
    const [flag, setFlag] = useState(false);
    const [otp, setOtp] = useState("");
    const [hideResend, setHideResend] = useState(true)

    const [counter, setCounter] = useState(1);

    useEffect(() =>{
        if(localStorage.getItem('token') && localStorage.getItem('token') !=='') {
            setPaymentPopup(true)
        } else {
            setPaymentPopup(false)
        }
        if(localStorage.getItem('payment') && localStorage.getItem('payment') !=='') {
            setIsPaymentDone(true)

        }
        checkLoggedInStatus()
    },[])

    const checkLoggedInStatus = async () => {
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/check-payment-status`, {
            customer_id: localStorage.getItem('customer_id').toString(),
            headers: { 'x-access-token': JSON.parse(localStorage.getItem('token')) }
          }).then((value) => {
            if (value.data.subscription) {
              setIsPaymentDone(true)
              localStorage.setItem('payment', true);
    
            } else {
              setIsPaymentDone(false)
              localStorage.removeItem('payment');
    
            }
            console.log("value", value);
          });
    
        } catch (err) {
          console.log(err);
          if(err.request.status == 401) {
            setLoggedInId('')
            setPaymentPopup('')
            localStorage.removeItem('token');
            localStorage.removeItem('customer_id');
            localStorage.removeItem('payment');
            localStorage.removeItem('number');
          }
          if(err.msg == 'Unauthorized') {
            
          }
        }
        var token = localStorage.getItem('token')
        if (localStorage.getItem('customer_id') === null) {
          console.log("tokennnnnnnnnnn", token);
          localStorage.removeItem('token');
    
        }
      }

    useEffect(() => {
        if (counter === 0) {
            setHideResend(false)
        }
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);



    const resendCode = (e) => {
        setHideResend(true)
        if (hideResend === false) {
            setCounter(59)
            resendOtp(e)
        }
    }

    function setUpRecaptcha(number) {
        if (Window.recaptchaVerifier) {
            return signInWithPhoneNumber(auth, number, Window.recaptchaVerifier);

        }
        Window.recaptchaVerifier = new RecaptchaVerifier(
            "recaptcha-container",
            { 'size': 'invisible' },
            auth
        );

        return signInWithPhoneNumber(auth, number, Window.recaptchaVerifier);

    }
    function setUpRecaptcha2(number) {
        if (Window.recaptchaVerifier) {
            return signInWithPhoneNumber(auth, number, Window.recaptchaVerifier);

        }

        Window.recaptchaVerifier = new RecaptchaVerifier(
            "recaptcha-container2",
            { 'size': 'invisible' },
            auth
        );
        return signInWithPhoneNumber(auth, number, Window.recaptchaVerifier);

    }

    const editFunction = (e) => {
        e.preventDefault()
        setFlag(false)
    }

    const getOtp = async (e) => {
        e.preventDefault();
        console.log(number);
        setError("");
        if (number === "" || number === undefined)
            return setError("Please enter a valid phone number!");
        load.current.style.display = 'flex'
        try {
            const response = await setUpRecaptcha(number);
            console.log(response);
            setResult(response);
            setFlag(true);
            setCounter(59)
            setHideResend(true)
            load.current.style.display = 'none'
        } catch (err) {
            setError(err.message);
            load.current.style.display = 'none'
        }
    };
    const resendOtp = async (e) => {

        console.log(number);
        setError("");

        if (number === "" || number === undefined)
            return setError("Please enter a valid phone number!");
        try {
            const response = await setUpRecaptcha2(number);
            console.log(response);
            setResult(response);
            setFlag(true);
            setCounter(59)
        } catch (err) {
            setError(err.message);
        }
    };
    const verifyOtp = async (e) => {
        e.preventDefault();
        setError("");

        if (otp === "" || otp === null) return setError("Please Enter a OTP");
        load.current.style.display = 'flex'

        try {
            const res = await result.confirm(otp);
            console.log("confirm", res._tokenResponse.idToken);

            try {
                const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/login-user`, {
                    mobile_number: number,
                    tokenid: res._tokenResponse.idToken
                });
                if (response.data.status == true) {
                    console.log(response.data.msg);
                    setLoggedInId(response.data.token)
                    if (response.data.token && response.data.token !== '') {

                        localStorage.setItem('token', JSON.stringify(response.data.token));
                    } else {
                        localStorage.removeItem('token');
                    }
                    localStorage.setItem('customer_id', response.data.user_id);
                    localStorage.setItem('number', JSON.stringify(number));

                    setPaymentPopup(true)
                    checkLoggedInStatus()
                } else {
                    console.log(response.data.msg);
                }
                load.current.style.display = 'none'
                setPaymentPopup(true)

            } catch (error) {
                console.log("Error", error);
                load.current.style.display = 'none'
            }
        } catch (err) {
            setError(err.message);
            load.current.style.display = 'none'

        }
    };



    return (
        <>
            <div>
                {(paymentPopup === true ) ? <StripeContainerExt mobile_number={number} load={load} setIsPaymentDone={setIsPaymentDone} /> :
                    <div>
                        <div className="ring-loader" ref={load} style={{ display: "none", placeContent: "center" }}>
                            <ClipLoader
                                size={250}
                                color={"#000"}
                            />
                        </div>
                        <Form onSubmit={getOtp} style={{ display: !flag ? "block" : "none" }}>
                            <Form.Group>
                                <div className='show-grid'>
                                    <Container>
                                        <Row className='sign-form justify-content-center pb-5'>
                                            <Col lg={6} md={12} className='for-app'>
                                                <div className='swiper-heading mb-2'>
                                                    <div className='title'><img src={mylogo} alt="" width={55} />&nbsp;<span className='mx-0'>TindyBot</span></div>
                                                </div>
                                                <div className="user-box">
                                                    <div className='tinder-baseline'><h3>Get Started</h3><p>By entering your phone number.</p></div>
                                                    <div className='extenstion-phone-input'>
                                                        <PhoneInput
                                                            international
                                                            defaultCountry='US'
                                                            value={number}
                                                            onChange={setNumber}
                                                            placeholder='Enter Your Phone Number' />
                                                    </div>
                                                </div>
                                                <div className='extenstion-login'>
                                                    <Button type='submit' className="payment-button-extenstion">
                                                        CONTINUE
                                                    </Button>
                                                </div>
                                                <div className='extenstion-information'>
                                                    <p>Click on "Continue" to receive a verification code. Message and data rates may apply.  By clicking continue, you agree to the Terms of Service and <br /> Privacy Policy.
                                                        {/* <a href="/">Learn what happens when your number changes</a> */}
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <div id="recaptcha-container" className='ml-2  mb-5 recaptcha-container'></div>
                                    <Col lg={7} md={12} className='error justify-content-center m-auto'>{error && <Alert variant="danger">{error}</Alert>}</Col>
                                </div>
                            </Form.Group>
                        </Form>
                        <Form className='hidden-form' onSubmit={verifyOtp} style={{ display: flag ? "block" : "none" }}>

                            <Container>
                                <Row className='justify-content-center'>
                                    {/* <Col lg={6} md={12} className='left-div'>
                                        <img src={picture} alt="sign-in" />
                                    </Col> */}
                                    <Col lg={6} md={12} className='pass-otp'>
                                        <div className='swiper-heading3'>
                                            <Modal.Title><img src={mylogo} alt="" className='logo-img' />&nbsp;<span>TindyBot</span></Modal.Title>
                                        </div>
                                        <h4 className='text-center mt-2 enter-otp'>Verify Phone Number</h4>
                                        <span className='msg'><h6 className='pt-2'>by entering the 6 digit verification code received on your phone number</h6>
                                            <h6 className='mobile-number text-center'>{number} <a onClick={(e) => { editFunction(e) }}><img src={pencil} alt="pencil" width={14} /></a></h6>
                                        </span>
                                        <Form.Group className="mb-3 mt-4 hide-form" controlId="formBasicOtp">
                                            <OTPInput
                                                value={otp}
                                                type="otp"
                                                otpType="number"
                                                OTPLength={6}
                                                required={true}
                                                // placeholder="Enter OTP"
                                                onChange={setOtp}
                                                // onChange={(e) => setOtp(e.target.value)}
                                                disabled={false}
                                                autoFocus
                                            />
                                        </Form.Group>
                                        <div className="Otp-btn text-center">
                                            <Button type="submit" variant="">
                                                Verify OTP
                                            </Button>
                                        </div>

                                        <h6 className='otp-status'>Didn’t receive OTP? {counter ? <span>Resend Code in  00:{counter}</span> : ''}
                                            {hideResend === true ? '' :
                                                <a className='ps-1' onClick={(e) => resendCode(e)}>RESEND CODE</a>
                                            }
                                        </h6>
                                        <Col md={12} className='error'>{error && <Alert variant="danger">{error}</Alert>}</Col>
                                        {/* <div className="App">
                        <button disabled={resendTime !== 0} onClick={handleResend}>
                            Resend OTP
                        </button>
                        {resendTime !== 0 && <span> in {resendTime} seconds</span>}
                </div> */}
                                    </Col>
                                </Row>
                            </Container>
                            <div id="recaptcha-container2" className='ml-2  mb-5 recaptcha-container'></div>
                        </Form>
                    </div>
                }
            </div>
        </>
    )
}

export default Extension;