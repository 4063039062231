import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { BsFillPinFill } from 'react-icons/bs'
import {IoExtensionPuzzle} from 'react-icons/io5'
const InstructionPopup = ({ showInst, setShowInst }) => {
    const handleClose = () => setShowInst(false);

    return (
        <div>
            <Modal show={showInst} onHide={handleClose} centered className='inst-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Instructions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center'>
                        <div className="thank">
                            Thank you for your purchase!
                        </div>
                        <div className="inst-light-text">

                            To begin swiping please follow our quick installation guide.
                        </div>
                    </div>
                    <div className='mt-3 font-weight-bold'>

                        Installation Guide using Chrome desktop web browser:
                    </div>
                    <div className="border-left mt-2 inst-steps">
                        <div className='d-flex align-items-center mb-4'>
                            <div className="step-circle">
                                1
                            </div>
                             Follow this link to the Google Chrome Store: 

                        </div>
                        <div className='d-flex align-items-center mb-4'>
                            <div className="step-circle">
                                2
                            </div>
                             ‘Add’ and then ‘Pin’ <BsFillPinFill className='mx-2'/> the TindyBot extension <IoExtensionPuzzle className='ms-2'/>

                        </div>
                        <div className='d-flex align-items-center mb-4'>
                            <div className="step-circle">
                                3
                            </div>
                             Sign in using your phone #

                        </div>
                        <div className='d-flex align-items-center mb-4'>
                            <div className="step-circle">
                                4
                            </div>
                             Open Tinder in a separate tab

                        </div>
                        <div className='d-flex align-items-center mb-4'>
                            <div className="step-circle-last">
                                5
                            </div>
                             Start auto-swiping!

                        </div>

                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default InstructionPopup