import React, {useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import homepic from '../components/images/home-t.png'
import LoginModal from './LoginModal';
import Button from 'react-bootstrap/Button';

export default function SectionOne({ loggedInId, setLoggedInId, load, isPaymentDone, setIsPaymentDone, paymentPopup, setPaymentPopup }) {
  const [show, setShow] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

   return (
      <>
         <Container className='sectionOne'>
            <Row>
               <Col className='sectionOneHeadingCol' lg={6} md={12} sm={12}>
                  <span>
                     <h1 className='swipe-Heading'>Automatic Tinder Swiping</h1>
                  </span>
                  <span className='swipe-sub-heading'>People Average 90 Minutes Swiping On Tinder Every Single Day. Start Auto Swiping, Get More Dates, And Earn Back Your Time!</span> <br />
                  {loggedInId ? '' :
                  <>
                  <Button className='Auto-Liking-btn' variant="" onClick={handleShow}>
                     <h5 style={{ margin: '0px' }}>Sign Up Today</h5>
                  </Button>
                  <p className='its-free'>$10 monthly</p>
                  </>
                  }
               </Col>
               <Col lg={5} md={12} sm={12} className='homepic'>
                  <img src={homepic} alt="" />
               </Col>
            </Row>
         </Container>
         <LoginModal show={show} handleClose={handleClose} setLoggedInId={setLoggedInId} loggedInId={loggedInId} showPaymentModal={showPaymentModal} load={load} setIsPaymentDone={setIsPaymentDone} setPaymentPopup={setPaymentPopup} paymentPopup={paymentPopup}/>
      </>
   )
}

