import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import LoginModal from './LoginModal';
import Example from './Payment';
import logo from '../components/images/mylogo.png';
// import {Router} from "react-router-dom"
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { hostname } from './hostname';
import { Modal } from 'react-bootstrap';

const notify = () => toast.success('Logged out successfully.');

function TopBar({ loggedInId, setLoggedInId, load, isPaymentDone, setIsPaymentDone, paymentPopup, setPaymentPopup, showInst, setShowInst, showSub, setShowSub }) {

  const [show, setShow] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [subStatus, setSubStatus] = useState(false)
  const [subValidTill, setSubValidTill] = useState(false)
  const [cancelSubDiv, setCancelSubDiv] = useState(false)
  const [subValid, setSubValid] = useState(false)
  const [cancelButton, setCancelButton] = useState(true)
  const [resubButton, setReSubButton] = useState(false)
  const [subDetails, setSubDetails] = useState(false)

  const handleClose = () => setShow(false);
  const handleCloseCancel = () => setShowCancelModal(false);
  const handleShow = () => setShow(true);
  const logout = async () => {
    console.log(logout);
    console.log(setLoggedInId);
    // setLoggedInId('')
    if (loggedInId && loggedInId !== '') {
      try {

        const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/logout`, {
          user_id: JSON.parse(localStorage.getItem('token')),
        }).then((response) => {
          console.log('hello', response);
          localStorage.removeItem('token');
          localStorage.removeItem('customer_id');
          localStorage.removeItem('payment');
          localStorage.removeItem('number');
          setIsPaymentDone(false)
          if (response.data.status === true) {
            setLoggedInId('')
            notify()

            delete axios.defaults.headers.common["x-access-token"];
            setSubStatus(false)
          }
          setShowPaymentModal(false)
          setPaymentPopup(false)

        });
      }
      catch (err) {
        console.log(err);
        toast.error(err.message)
      }
    }
  }
  const todayDate = new Date()
  const checkLoggedInStatus = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/check-payment-status`, {
        customer_id: localStorage.getItem('customer_id').toString(),
        headers: { 'x-access-token': JSON.parse(localStorage.getItem('token')) }
      }).then((value) => {
        // if (value.data.subscription) {
        //   setIsPaymentDone(true)
        //   localStorage.setItem('payment', true);
        //   setShowInst(true)
        //   setShowPaymentModal(false)
        //   handleClose()
        //   setShowSub(true)
        //   if (value.data.cancel_at_end) {
        //     setShowSub(false)

        //   } else {
        //     setShowSub(true)
        //   }
        //   setSubStatus(value.data.subscription)
        //   if (value.data.current_period_end) {
        //     var dateObj = new Date(value.data.current_period_end * 1000)
        //     var todayDate = new Date()
        //     if (dateObj) {
        //       if (dateObj > todayDate) {
        //         setSubValid(true)
        //       } else {
        //         setSubValid(false)

        //       }
        //     }
        //     console.log(dateObj);
        //     var date = dateObj.getDate() + '/' + (dateObj.getMonth() + 1) + '/' + dateObj.getFullYear()
        //   }
        //   setSubValidTill(date)
        // } else {
        //   setIsPaymentDone(false)
        //   localStorage.removeItem('payment');

        // }
        var dateObj = new Date(value.data.current_period_end * 1000)
        var date = dateObj.getDate() + '/' + (dateObj.getMonth() + 1) + '/' + dateObj.getFullYear()
        if (value.data.subscription == 'active') {
          setIsPaymentDone(true)
          setShowSub(true)
          localStorage.setItem('payment', true);
          setSubStatus(true)
          setSubValidTill(date)
          setShowInst(true)
        } else if (value.data.subscription == 'canceled') {
          if (value.data.current_period_end) {
            if (dateObj > todayDate) {
              setReSubButton(true)
              setCancelButton(false)
							setCancelSubDiv(false)
							setSubDetails(true)
              setIsPaymentDone(true)
              setShowSub(true)
              setSubValidTill(date)
              setSubStatus(true)
              setShowInst(true)

            } else {
              setSubStatus(false)

            }
          }

        }
        console.log("value", value);
      });

    } catch (err) {
      console.log(err);
      if(err.request) {

      
      if (err.request.status == 401) {
        setLoggedInId('')
        setPaymentPopup('')
        localStorage.removeItem('token');
        localStorage.removeItem('customer_id');
        localStorage.removeItem('payment');
        localStorage.removeItem('number');
      }
    }
    }
    var token = localStorage.getItem('token')
    if (localStorage.getItem('customer_id') === null) {
      console.log("tokennnnnnnnnnn", token);
      localStorage.removeItem('token');

    }
  }
  useEffect(() => {
    checkLoggedInStatus()
  }, [])

  useEffect(() => {
    console.log(showSub);
  }, [showSub])

  const showPaymentModalHandler = () => {
    handleShow()
    setShowPaymentModal(true)

  }
  const cancelSubFuncSubmit = () => {
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/cancel-subscription`, {
      customer_id: localStorage.getItem('customer_id').toString(),
      headers: { 'x-access-token': JSON.parse(localStorage.getItem('token')) }
    }).then((value) => {
      console.log(value);
      if (value.data.status) {
        toast.success(value.data.msg)
        // setShowCancelModal(false)
        setShowSub(true)
        checkLoggedInStatus()
        setSubDetails(true)
      }
    })
      .catch(err => {
        toast.error(err.message)
      })

  }

  const cancelSubFunc = () => {


  }
  return (
    <>
      <Toaster />
      <Navbar key={'lg'} bg="" expand={'lg'} className="mb-3">
        <Container className='navbar'>
          <span className='logo'>
            <Navbar.Brand href="#"><img src={logo} alt="" /><a href="/">TindyBot</a></Navbar.Brand>
          </span>
          <Navbar className='login-btn'
            id={`offcanvasNavbar-expand-${'lg'}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${'lg'}`}
            placement="end"
          >
            <Nav className="justify-content-center flex-grow-1 pe-3">
            </Nav>
            <Example />
            {(loggedInId === '' || !loggedInId) ?
              <Button variant="" onClick={handleShow}>
                Login
              </Button>
              :
              <div className='d-flex'>
                {isPaymentDone ? '' :

                  <Button className='me-3' onClick={() => showPaymentModalHandler()}>
                    Subscribe
                  </Button>
                }
                {showSub ?

                  <Button className='me-3 cancel-btn' onClick={() => setShowCancelModal(true)}>
                    Subscription
                  </Button>
                  : ''}
                <Button variant="" onClick={() => logout()}>
                  Logout
                </Button>
              </div>
            }

          </Navbar>
        </Container>
      </Navbar>
      <LoginModal show={show} handleClose={handleClose} setLoggedInId={setLoggedInId} loggedInId={loggedInId} showPaymentModal={showPaymentModal} load={load} setIsPaymentDone={setIsPaymentDone} setPaymentPopup={setPaymentPopup} paymentPopup={paymentPopup} checkLoggedInStatus={checkLoggedInStatus} />
      <Modal show={showCancelModal} onHide={handleCloseCancel} centered className='cancel-modal'>
        <Modal.Header closeButton>
          <Modal.Title>Subscription Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>

            Subscription Status: {subStatus ? 'True' : 'False'}
          </div>
          <div>

            Subscription Valid Till: {subValidTill}
          </div>
          {cancelButton ?
            <Button variant="danger" className='mt-3' onClick={() => setCancelSubDiv(!cancelSubDiv)}>
              Cancel Subscription
            </Button>
            : ''
          }
          {cancelSubDiv ?
            <div className='mt-2 card p-3'>

              <div className='mb-2'>Are you sure you want to cancel your subscription?</div>
              <div className='d-flex justify-content-end'>
                <Button variant="secondary" className='me-3' onClick={() => setCancelSubDiv(!cancelSubDiv)}>No</Button>
                <Button variant="danger" onClick={() => cancelSubFuncSubmit()}>
                  Yes
                </Button>
              </div>
            </div>
            : ''}
          {subDetails ?
            <div className='card p-2'>
              Your subscription plan has been cancelled
              <div>
                You can keep using your account until your next payment was due
              </div>

            </div>
            : ""
          }
          {resubButton ?
            <Button variant="" className='resub-button mt-3' onClick={() => showPaymentModalHandler()}>
              Resubscribe
            </Button>
            : ''}
        </Modal.Body>

      </Modal>

    </>
  );
}

export default TopBar;