import React, { useState, useEffect, useRef } from 'react'
// import { Link, useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { Form, Alert } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import PhoneInput from 'react-phone-number-input';
import "react-phone-number-input/style.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'react-phone-number-input/style.css';
import picture from '../components/images/key-concept-illustration_114360-6305.webp';
import OTPInput from "otp-input-react";
import logo from '../components/images/logo-white.svg';
import mylogo from '../components/images/mylogo.png';
import axios from 'axios';
import pencil from './images/1416596-200.png'
import toast, { Toaster } from 'react-hot-toast';

// import { useTimer } from 'use-timer';
import {
    RecaptchaVerifier,
    signInWithPhoneNumber,
} from "firebase/auth";
import { auth } from '../firebase'
import StripeContainer from './StripeContainer';
import ClipLoader from "react-spinners/ClipLoader";
import {hostname} from './hostname'

function LoginModal({ show, handleClose, setLoggedInId, paymentPopupProp, load, showPaymentModal, setIsPaymentDone,  paymentPopup, setPaymentPopup, checkLoggedInStatus }) {
    const [error, setError] = useState("");
    // const {setUpRecaptcha} = useUserAuth();
    const [number, setNumber] = useState("");
    const [result, setResult] = useState("");
    const [flag, setFlag] = useState(false);
    const [otp, setOtp] = useState("");
    // const navigate = useNavigate();
    const [hideResend, setHideResend] = useState(true)

    const [counter, setCounter] = useState(1);

    useEffect(() =>{
        if(localStorage.getItem('token') && localStorage.getItem('token') !=='') {
            setPaymentPopup(true)
        } else {
            setPaymentPopup(false)
        }
    },[])

    useEffect(() => {
        // if(flag === true) {
            if(counter === 0) {
                setHideResend(false)
            }
            const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
            //console.log(timer);
            return () => clearInterval(timer);
        // }
    }, [counter]);

    

    const resendCode = (e) =>{
        setHideResend(true)
        if(hideResend === false) {
            setCounter(59)

            resendOtp(e)
        }
    }

    function setUpRecaptcha(number) {
        if(Window.recaptchaVerifier) {
            return signInWithPhoneNumber(auth, number, Window.recaptchaVerifier);
    
            }
         Window.recaptchaVerifier = new RecaptchaVerifier(
            "recaptcha-container",
            {'size': 'invisible'},
            auth
        );
        // recaptchaVerifier.render();
        return signInWithPhoneNumber(auth, number, Window.recaptchaVerifier);
        
    }
    function setUpRecaptcha2(number) {
        if(Window.recaptchaVerifier) {
        return signInWithPhoneNumber(auth, number, Window.recaptchaVerifier);

        }
        // Window.recaptchaVerifier.clear()
        
        Window.recaptchaVerifier = new RecaptchaVerifier(
            "recaptcha-container2",
            {'size': 'invisible'},
            auth
        );
        // recaptchaVerifier.render();
        return signInWithPhoneNumber(auth, number, Window.recaptchaVerifier);
        
    }
    
    const editFunction = (e) =>{
        e.preventDefault()
        setFlag(false)
    }

    const getOtp = async (e) => {
        e.preventDefault();
        console.log(number);
        setError("");
        if (number === "" || number === undefined)
            return setError("Please enter a valid phone number!");
        
        load.current.style.display='flex'
        try {
            const response = await setUpRecaptcha(number);
            console.log(response);
            setResult(response);
            setFlag(true);
            setCounter(59)
            setHideResend(true)
            load.current.style.display='none'
        } catch (err) {
            setError(err.message);
            load.current.style.display='none'
        }
    };

   

    const resendOtp = async (e) => {

        console.log(number);
        setError("");
        
        if (number === "" || number === undefined)
            return setError("Please enter a valid phone number!");
        try {
            const response = await setUpRecaptcha2(number);
            console.log(response);
            setResult(response);
            setFlag(true);
            setCounter(59)
        } catch (err) {
            setError(err.message);
        }
    };
    const verifyOtp = async (e) => { //setPaymentPopup(true)
        e.preventDefault();
        setError("");
        /* try {
            const response = await axios.post('http://localhost:4050/create-user', {
                mobile_number: number
            }); 
            if (response.data.status == true) {
                console.log(response.data.msg);
            }else{
                console.log(response.data.msg);
            }
        } catch (error) {
            console.log("Error", error);
        } */
        
        if (otp === "" || otp === null) return setError("Please Enter a OTP");
        load.current.style.display='flex'

        try {
            const res = await result.confirm(otp);
            console.log("confirm", res._tokenResponse.idToken);
            
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/login-user`, {
                    mobile_number: number,
                    tokenid: res._tokenResponse.idToken
                }); 
                if (response.data.status == true) {
                    console.log(response.data.msg);
                    setLoggedInId(response.data.token)
                    if(response.data.token && response.data.token !=='') {

                        localStorage.setItem('token', JSON.stringify(response.data.token));
                    } else {
                        localStorage.removeItem('token');
                    }
                    localStorage.setItem('customer_id',response.data.user_id);
                    localStorage.setItem('number', JSON.stringify(number));

                    setPaymentPopup(true)
                    checkLoggedInStatus()
                }else{
                    console.log(response.data.msg);
                    toast.error(response.data.msg)
                }
                load.current.style.display='none'

            } catch (error) {
                console.log("Error", error);
                toast.error(error.message)
                load.current.style.display='none'
            }
        } catch (err) {
            setError(err.message);
            load.current.style.display='none'

        }
    };

   

    return (
        <>
            <Modal className='swiper-modal' show={show} onHide={handleClose} size='lg' aria-labelledby="contained-modal-title-vcenter" centered>
                {paymentPopup === true || paymentPopupProp === true ? <StripeContainer mobile_number={number} load={load} showPaymentModal={showPaymentModal} setIsPaymentDone={setIsPaymentDone} setPaymentPopup={setPaymentPopup}/>:
                <div>
                <div className="ring-loader" ref={load} style={{ display: "none", placeContent: "center"}}>
                <ClipLoader 
                size={250} 
                color={"#000"} 
                 />
            </div> 
                <Form onSubmit={getOtp} style={{ display: !flag ? "block" : "none" }}>
                    <Form.Group>
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body className='show-grid'>
                            <Container>
                                <Row className='sign-form'>
                                    <Col lg={6} md={12} className='sign-in'>
                                        <img src={picture} alt="sign-in" />
                                    </Col>
                                    <Col lg={6} md={12} className='for-app'>
                                        <div className='swiper-heading mb-2'>
                                            <Modal.Title><img src={mylogo} alt="" width={55} />&nbsp;<span>TindyBot</span></Modal.Title>
                                        </div>
                                        <div className="user-box">
                                            <div className='tinder-baseline'><h3>Get Started</h3><p>By entering your phone number.</p></div>
                                            <PhoneInput
                                                international
                                                defaultCountry='US'
                                                value={number}
                                                onChange={setNumber}
                                                placeholder='Enter Your Phone Number' />
                                        </div>
                                        <div id="recaptcha-container" className='ml-2  mb-5 recaptcha-container'></div>
                                        <Button type='submit' className="payment-button">
                                            CONTINUE
                                        </Button>
                                        <div className='information'>
                                            <p>Click on "Continue" to receive a verification code. Message and data rates may apply.  By clicking continue, you agree to the Terms of Service and <br /> Privacy Policy.
                                                {/* <a href="/">Learn what happens when your number changes</a> */}
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                            <Col md={12} className='error'>{error && <Alert variant="danger">{error}</Alert>}</Col>
                        </Modal.Body>
                    </Form.Group>
                </Form>
                <Form className='hidden-form' onSubmit={verifyOtp} style={{ display: flag ? "block" : "none" }}>
                    <Modal.Header closeButton>
                    </Modal.Header>

                    <Container>
                        <Row>
                            <Col lg={6} md={12} className='left-div'>
                                <img src={picture} alt="sign-in" />
                            </Col>
                            <Col lg={6} md={12} className='pass-otp'>
                                <div className='swiper-heading2'>
                                    <Modal.Title><img src={mylogo} alt="" className='logo-img' />&nbsp;<span>TindyBot</span></Modal.Title>
                                </div>
                                <h4 className='text-center mt-2 enter-otp'>Verify Phone Number</h4>
                                <span className='msg'><h6 className='pt-2'>by entering the 6 digit verification code received on your phone number</h6>
                                    <h6 className='mobile-number text-center'>{number} <a onClick={(e) =>{editFunction(e)}}><img src={pencil} alt="pencil" width={14} /></a></h6>
                                </span>
                                <Form.Group className="mb-3 mt-4 hide-form" controlId="formBasicOtp">
                                    <OTPInput
                                        value={otp}
                                        type="otp"
                                        otpType="number"
                                        OTPLength={6}
                                        required={true}
                                        // placeholder="Enter OTP"
                                        onChange={setOtp}
                                        // onChange={(e) => setOtp(e.target.value)}
                                        disabled={false}
                                        autoFocus
                                    />
                                </Form.Group>
                                <div className="Otp-btn text-center">
                                    <Button type="submit" variant="">
                                        Verify OTP
                                    </Button>
                                </div>
                                <div id="recaptcha-container2" className='ml-2  mb-5 recaptcha-container'></div>

                                <h6 className='otp-status'>Didn’t receive OTP? {counter ? <span>Resend Code in  00:{counter}</span> : ''}     
                                {hideResend === true ? '' :
                                <a className='ps-1' onClick={(e) =>resendCode(e)}>RESEND CODE</a>
                                }
                                 </h6>
                                 <Col md={12} className='error'>{error && <Alert variant="danger">{error}</Alert>}</Col>
                                {/* <div className="App">
                        <button disabled={resendTime !== 0} onClick={handleResend}>
                            Resend OTP
                        </button>
                        {resendTime !== 0 && <span> in {resendTime} seconds</span>}
                </div> */}
                            </Col>
                        </Row>
                    </Container>
                </Form>
                </div>
}
            </Modal>
        </>
    )
}

export default LoginModal