import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import PaymentFormExt from './PaymentFormExt';
// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';

const PUBLIC_KEY = "pk_test_51LaGSHEbDu7EHyqlyNVlflZVdK01eZuO5rpYhNFhdn1zmHIDxvaOLPPburAUTpmYL6SSthh5vXbdNg7YIp5C4dwS00M5nbJab6"
const stripeTestPromise = loadStripe(PUBLIC_KEY)
export default function StripeContainer(props) {

    return (
        <>
                
                     
                        <Elements stripe={stripeTestPromise}>
                            <PaymentFormExt mobile_number={props.mobile_number} load={props.load} setShowPaymentModal={props.setShowPaymentModal} setIsPaymentDone={props.setIsPaymentDone}/>
                        </Elements>

        </>
    )
}