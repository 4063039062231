import React from 'react'
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import facebook from '../components/images/facebook2-white.svg';
// import twitter from '../components/images/twitter-white.svg';
import whitelogo from '../components/images/tindybot-logo-white.png';

export default function Footer() {
  return (
    <>
      <section className='footer'>
        <Container className='footered'>
          <Row className='footer-main'>
            <Col md={12} sm={12}>
              <li><img src={whitelogo} alt="" width={70} height={70} /></li>
              {/* <li><a href="/">How To Use</a></li>
        <li><a href="/">Pricing</a></li>
        <li><a href="/">Blog</a></li>
        <li><a href="/">Help</a></li> */}
            </Col>
            {/* <div className='links'>Follow Us &nbsp;
              <a rel="noreferrer" target="_blank" href="https://www.facebook.com/"><img src={facebook} alt="facebook" width={25} height={25} /></a> &nbsp;
              <a rel="noreferrer" target="_blank" href="https://twitter.com/i/flow/login"><img src={twitter} alt="twitter" width={25} height={25} /></a>
            </div> */}
          </Row>
          <Row className='footer-content'>
            {/* <Col md={12} sm={12}>
              © TindyBot 
            </Col> */}
            {/* <Col md={12} sm={12}>
              <a href="/">Terms of Use</a> <a href="/"> Privacy Policy</a>
            </Col> */}
            <Col md={12} sm={12} className="text-start d-lg-inline-flex inoid">
              <p>Interested in our refer to earn program or have questions? Reach out to us at <a target="_blank" href="mailto:hello@tindybot.com">hello@tindybot.com</a>
              </p>
            </Col>
            <Col md={12} sm={12} className="text-start d-lg-inline-flex inoid">
              <p>All Rights Reserved. TindyBot 2022</p>
            </Col>
            <h6 className='d-none'> <a target="_blank" rel="noreferrer" href="https://www.inoidsolutions.com" >Developed by: <span className='inoidLink'>iNoid Solutions</span></a></h6>
          </Row>
        </Container>
      </section>
    </>
  )
}
