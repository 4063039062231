import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation } from "swiper";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import { Container } from 'react-bootstrap';
import swiperone from '../components/images/download.png';
import swipersecond from '../components/images/download2.png';
import swiperthree from '../components/images/download3.png';

export default function SectionSix() {
  return (
   <>
   <Container className='swiper-section'>
    <Row>
    <Col sm={12}  md={2}></Col>
    <Col sm={12} md={8}>
    <Swiper
        slidesPerView={1}
        slidesPerGroup={1}
        loop={true}
        centeredSlides={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide> <img src={swiperone} alt={swiperone} /> <br />
        <p><h4 className='reviewSwiperPerson'>Mathew Voric</h4>Runs great! I've gotten so many matches with this extension. Would definitely recommend this to anyone who's thinking about giving it a try. It's easy to set up.</p>
        </SwiperSlide>
        <SwiperSlide> <img src={swipersecond} alt={swipersecond}/>
        <br />
        <p><h4 className='reviewSwiperPerson'>Joan Justine Mukiss</h4>TindyBot meet Tinder. Now you can use TindyBot to auto swipe with Tinder as well. Maximize your matches by auto liking on Tinder.</p></SwiperSlide>
        <SwiperSlide><img src={swiperthree} alt={swiperthree} /><br />
        <p><h4 className='reviewSwiperPerson'>Jack Lucid</h4>Was pretty suspicious at first since most of it's features are free. But I installed the extension, signed up on their site and was automatically swiping in no time. Would be nice if they increased the daily likes</p></SwiperSlide>
        <SwiperSlide> <img src={swiperone} alt={swiperone}/><br />
        <p><h4 className='reviewSwiperPerson'>John</h4>TindyBot meet Tinder. Now you can use TindyBot to auto swipe with Tinder as well. Maximize your matches by auto liking on Tinder.</p></SwiperSlide>
        <SwiperSlide> <img src={swipersecond} alt={swipersecond}/><br />
        <p><h4 className='reviewSwiperPerson'>Max Coogan</h4>TindyBot meet Tinder. Now you can use TindyBot to auto swipe with Tinder as well. Maximize your matches by auto liking on Tinder.</p></SwiperSlide>
        <SwiperSlide><img src={swiperthree} alt={swiperthree} /><br />
        <p><h4 className='reviewSwiperPerson'>Rhaegul Myrktion</h4>TindyBot meet Tinder. Now you can use TindyBot to auto swipe with Tinder as well. Maximize your matches by auto liking on Tinder.</p></SwiperSlide>
        <SwiperSlide><img src={swiperone} alt={swiperone}/><br />
        <p><h4 className='reviewSwiperPerson'>Rose Roll</h4>TindyBot meet Tinder. Now you can use TindyBot to auto swipe with Tinder as well. Maximize your matches by auto liking on Tinder.</p></SwiperSlide>
      </Swiper>
    </Col>
    <Col sm={12} md={2}></Col>
      </Row>
      </Container>
   </>
  )
}
