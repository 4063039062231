import React from 'react'
import checkmark from './images/checkmark.png'
const AlreadyLoggedIn = () => {
    return (
        <div className='d-flex flex-column justify-content-center align-items-center vh-100'>
            <div className='d-flex align-items-center'>

                You are successfully logged in.
                <img style={{ width: '20px', marginLeft: '10px' }} src={checkmark} alt="" />

            </div>
            You can close this popup now!
        </div>
    )
}

export default AlreadyLoggedIn