import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import LikePic from '../components/images/like.png'
import Card from 'react-bootstrap/Card';
// import CardImg1 from '../components/images/mylogo.png';
import paylogo from '../components/images/pay-icon.svg';
import setlogo from '../components/images/set-icon.svg';
import signupLogo from '../components/images/sign-up-icon.svg';



export default function SectionSecond() {
  return (
    <>
      <section className='Second-Section'>
        <Container className='Second'>
          <Row>
            <Col lg={1} md={12} sm={12}></Col>
            <Col lg={10} md={12} sm={12} className='it-work-heading'>
              <h2>How Does It Work?</h2>
            </Col>
            <Col lg={1} md={12} sm={12}></Col>
          </Row>
          <Row>
            <Col lg={1} md={12} sm={12}></Col>
            <Col lg={10} md={12} sm={12} className='it-work-cards'>
              <Row className='card-row'>
                <Col lg={4} md={12} sm={12}>
                  <Card className='card' style={{ height: '14rem' }}>
                    <Card.Img className='cardImg' variant="top" src={signupLogo} />
                    <Card.Body>
                      <Card.Title>Sign Up</Card.Title>
                      <Card.Text>
                      Register with your phone number
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={4} md={12} sm={12}>
                  <Card className='card' style={{ height: '14rem' }}>
                    <Card.Img className='cardImg' variant="top" src={paylogo} />
                    <Card.Body>
                      <Card.Title>Pay</Card.Title>
                      <Card.Text className='card-text'>
                      $10 per month. Cancel anytime.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={4} md={12} sm={12}>
                  <Card className='card' style={{ height: '14rem' }}>
                    <Card.Img className='cardImg' variant="top" src={setlogo} />
                    <Card.Body>
                      <Card.Title>Set & Forget</Card.Title>
                      <Card.Text>
                      Login. Customize your swipe settings. Start auto swiping. It’s that easy!
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col lg={1} md={12} sm={12}></Col>
          </Row>
        </Container>
      </section>
    </>
  )
}
