import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBH_aAA-Zmq3Qe27M-1vttg3Gx6DyUEFt0",
  authDomain: "super-swiper.firebaseapp.com",
  projectId: "super-swiper",
  storageBucket: "super-swiper.appspot.com",
  messagingSenderId: "91273203500",
  appId: "1:91273203500:web:08ef226cebc7528f222689"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;
