import React from 'react'
import Footer from './Footer'
import SectionOne from './SectionOne'
import SectionSecond from './SectionSecond'
import SectionSix from './SectionSix'
import TopBar from './TopBar'

export default function Home({ load, loggedInId, setLoggedInId, isPaymentDone,  setIsPaymentDone, paymentPopup, setPaymentPopup, showInst, setShowInst, showSub, setShowSub}) {
    return (
        <>
            <TopBar load={load} loggedInId={loggedInId} setLoggedInId={setLoggedInId} isPaymentDone={isPaymentDone} setIsPaymentDone={setIsPaymentDone} setPaymentPopup={setPaymentPopup} paymentPopup={paymentPopup} showInst={showInst} setShowInst={setShowInst} showSub={showSub} setShowSub={setShowSub}/>
            <SectionOne load={load} loggedInId={loggedInId} setLoggedInId={setLoggedInId} isPaymentDone={isPaymentDone} setIsPaymentDone={setIsPaymentDone} setPaymentPopup={setPaymentPopup} paymentPopup={paymentPopup}/>
            <SectionSecond />
            <SectionSix />
            <Footer />
        </>
    )
}

